<template>
  <div>
    <el-form
      :label-position="'left'"
      label-width="80px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item label="状态">
        <el-select style="width: 100%" v-model="requestContent.status" placeholder="请选择">
          <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="courseId">
        <el-input
          :disabled="editorStatus"
          v-model="requestContent.courseId"
          @blur.prevent="blurToQueryCourse()"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input v-model="requestContent.userPhone"></el-input>
      </el-form-item>
      <el-form-item label="课程名">
        <el-input v-model="requestContent.courseName" placeholder="随便写，以系统的为准"></el-input>
      </el-form-item>

      <el-form-item label="支付渠道">
        <el-select style="width: 100%" v-model="requestContent.payChannel" placeholder="请选择">
          <el-option v-for="item in payChannelOption" :key="item.value" :label="item.label" :value="item.value">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="过期时间">
        <el-date-picker
          value-format="timestamp"
          style="width: 100%"
          v-model="requestContent.expireTime"
          type="datetime"
          placeholder="选择日期时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" :rows="8" v-model="requestContent.remark"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      :label-position="'left'"
      label-width="80px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item class="bottomBtn">
        <el-button @click="saveContent">保存</el-button>
        <el-button>取消</el-button>
        <!-- <el-button @click="publiceArticle" type="primary">立即发布</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { alertMessage } from "@/util/alertMessageUtil";
import { orderStatusEnum, payChannelEnum } from "@/util/EnumUtil";
import { aidaOrderApi } from "@/api/aidaOrderApi";
import { aidaCourseApi } from "@/api/aidaCourseApi";
export default {
  data() {
    return {
      PRE_CREATE_FAIL: orderStatusEnum.PRE_CREATE_FAIL,
      PRE_CREATE: orderStatusEnum.PRE_CREATE,
      USER_CANCEL: orderStatusEnum.USER_CANCEL,
      EXPIRED: orderStatusEnum.EXPIRED,
      CANCEL: orderStatusEnum.CANCEL,
      UNPAID: orderStatusEnum.UNPAID,
      PAID: orderStatusEnum.PAID,
      REFUND: orderStatusEnum.REFUND,
      requestStatus: false,
      keywords: "",
      saveStatus: false,
      editorStatus: false,
      statusOption: [
        {
          value: orderStatusEnum.PRE_CREATE_FAIL,
          label: "预创建失败",
        },
        {
          value: orderStatusEnum.PRE_CREATE,
          label: "预创建",
        },
        {
          value: orderStatusEnum.USER_CANCEL,
          label: "用户主动取消",
        },
        {
          value: orderStatusEnum.EXPIRED,
          label: "已失效",
        },
        {
          value: orderStatusEnum.CANCEL,
          label: "取消订单",
        },
        {
          value: orderStatusEnum.UNPAID,
          label: "未支付",
        },
        {
          value: orderStatusEnum.PAID,
          label: "已支付",
        },
        {
          value: orderStatusEnum.REFUND,
          label: "已退款",
        },
      ],
      payChannelOption: [
        {
          value: payChannelEnum.none,
          label: "none",
        },
        {
          value: payChannelEnum.user,
          label: "user",
        },
        {
          value: payChannelEnum.cms,
          label: "cms",
        },
        {
          value: payChannelEnum.xiaoe,
          label: "xiaoe",
        },
        {
          value: payChannelEnum.free_gift,
          label: "free_gift",
        },
        {
          value: payChannelEnum.exchange_ticket,
          label: "兑换券",
        },
      ],
      requestContent: {
        id: "0",
        courseId: "",
        status: orderStatusEnum.PAID,
        userPhone: "",
        courseName: "",
        payChannel: payChannelEnum.cms,
        expireTime: new Date().getTime() + 86400 * 1000 * 365, // 一年后
        title: "",
        remark: "",
      },
    };
  },
  methods: {
    saveContent() {
      this.submitOrder();
    },
    submitOrder() {
      this.requestStatus = true;
      let alertType = "warning";
      let alertText = "请完善courseId";
      if (this.requestContent.courseId === "") {
        alertMessage(alertText, alertType);
        return;
      }
      alertText = "请完善手机号";
      if (this.requestContent.userPhone === "" || this.requestContent.userPhone.length < 11) {
        alertMessage(alertText, alertType);
        return;
      }
      alertText = "请完善课程名";
      if (!this.requestContent.courseName) {
        alertMessage(alertText, alertType);
        return;
      }
      alertText = "请完善支付渠道";

      if (this.requestContent.payChannel === "") {
        alertMessage(alertText, alertType);
        return;
      }
      if (this.requestStatus) {
        aidaOrderApi.saveOrder(this.requestContent).then((ret) => {
          if (ret.code == 0) {
            alertMessage("保存成功", "success");
            this.requestContent = ret.data;
            this.saveStatus = true;
          } else {
            alertMessage(ret.msg, "warning");
          }
        });
      }
    },
    blurToQueryCourse() {
      aidaCourseApi
        .getCourseById(this.requestContent.courseId)
        .then((ret) => {
          if (ret.code == 0) {
            if (ret.data) {
              this.requestContent.courseName = ret.data.courseName;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          alertMessage(err, "warning");
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.requestBtnText = "立即修改";
      this.editorStatus = true;
      aidaOrderApi.getOrderById(this.$route.params.id).then((ret) => {
        console.log("---ret.data", ret.data);
        if (ret.code === 0) {
          this.requestContent = ret.data;
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    }
  },
};
</script>

<style></style>
